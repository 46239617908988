import type { TApplicationState } from "~/components/global/application/types/TApplicationState";

export class ApplicationState {
	constructor(private _state: Ref<TApplicationState>) {}

	get state(): TApplicationState {
		return this._state.value;
	}

	set state(value: TApplicationState) {
		this._state.value = value;
	}
}
